import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"


import img1 from '../images/jaccuzi/1.jpeg';
import img2 from '../images/jaccuzi/2.jpeg';
import img3 from '../images/jaccuzi/3.jpeg';
import img4 from '../images/jaccuzi/4.jpeg';
import img5 from '../images/jaccuzi/5.jpeg';
import img6 from '../images/jaccuzi/6.jpeg';
import img7 from '../images/jaccuzi/7.jpeg';
import img8 from '../images/jaccuzi/8.jpeg';


import raty from '../images/raty-banner.jpg'

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img7,
    thumbnail: img7,
  },
  {
    original: img8,
    thumbnail: img8,
  }
];

const ProductView14 = () => {

  const price = 13999;

  return(
  <Layout>
    <SEO 
      title="Jacuzzi" 
      description = { SEO.description }
      urlName="jacuzzi-12-dysz-led"
    />
    <div className="banner">
        <div className="bannerPlate">
          <p>Jacuzzi</p>
        </div>
    </div>
    <div className="productViewPlate">

      <div className="gallery">
        <ImageGallery slideInterval="2000" items={images} />
      </div>
      <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Jacuzzi 12 dysz AIR LED</div>
          <div id="productPrice">{price}zł
          </div>
          <div className="productOption">
              <div className="productDescriptionText">
                  <h3>Opis</h3>
                  <p>
                  Jacuzzi ogrodowe o wymiarach 192cm na 192cm to doskonały sposób na relaks w każdej porze roku. Posiada pompę z termostatem o mocy 3KW dzięki czemu jest wyposażone aż w 12 dysz. Jacuzzi posiada regulację temperatury oraz podświetlenie LED.<br/>
                  </p>
                  <h3>Dane techniczne:</h3>
                  <ul>
                    <li>Podświetlenie LED</li>
                    <li>Termo pokrywa izolująca ciepło</li>
                    <li>Funkcja AIR</li>
                    <li>Pompa z termostatem 3KW</li>
                    <li>12 dysz masujących</li>
                    <li>Jacuzzi na stelażu metalowym</li>
                  </ul>
                  <a href="https://epos.inbank.pl/pl/drewhaus">
                  <img src={raty} className="creditBanner" alt="domek-ogrodowy-raty"/>
                  </a>
                  <p><b>Jacuzzi ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do zakładki&nbsp; 
                    <Link to="/contact" style={{ color: '#765F52' }} >
                      kontakt
                    </Link> i sprawdź gdzie nas znajdziesz!</b></p>

              </div>
          </div>
      </div>
    </div>
  </Layout>
  )}

export default ProductView14


